export default function IconcCaps() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
                d="M17.0393 24.9999H17.0153C16.9955 24.9988 16.9668 24.9968 16.9323 24.9939C16.537 24.9583 16.1498 24.8604 15.7852 24.7037C15.3624 24.5292 14.968 24.2925 14.6151 24.0015C13.9147 23.4293 13.4917 22.845 13.0827 22.2799C12.7811 21.8631 12.4691 21.4323 12.0374 20.9957C11.7505 20.7053 11.1033 20.0512 10.2177 19.6008C10.0051 20.0776 9.73544 20.5269 9.4146 20.9388C8.72915 21.81 7.89514 22.3348 7.00307 22.4569C6.65456 22.5045 5.72981 22.6311 4.93627 22.0141C4.19544 21.4381 4.03664 20.5881 4.00217 20.2493C3.99553 20.1828 4.00614 20.1157 4.03298 20.0545C4.05982 19.9932 4.10198 19.9399 4.1554 19.8997C4.7639 19.4416 5.43601 19.0604 6.09878 18.797C7.27908 18.3284 8.4149 18.2259 9.47488 18.4923C11.0493 18.8882 12.1689 20.0218 12.5905 20.4495C13.0636 20.9283 13.3932 21.3836 13.7119 21.8242C14.1091 22.3731 14.4843 22.8917 15.1066 23.3997C15.4002 23.6413 15.7282 23.8377 16.0798 23.9824C16.3715 24.1091 16.6815 24.1885 16.9982 24.2175L17.0324 24.2202L17.2808 24.195C19.6617 23.4376 21.6644 21.8013 22.8812 19.6193L23.5606 19.9989C22.2402 22.3663 20.063 24.1379 17.4765 24.9494C17.4512 24.9574 17.4251 24.9628 17.3987 24.9654L17.0784 24.9981C17.0654 24.9994 17.0524 25 17.0393 24.9999ZM4.81172 20.3846C4.87288 20.6737 5.02679 21.0963 5.41488 21.3989C5.94865 21.8148 6.59029 21.7272 6.8981 21.6852C7.59623 21.5898 8.23742 21.1765 8.80366 20.4568C9.07989 20.1023 9.31256 19.7159 9.49667 19.3059C9.42728 19.2844 9.35693 19.2647 9.28561 19.2467C8.38732 19.0208 7.41207 19.1133 6.38657 19.5198C5.82945 19.7452 5.30085 20.0354 4.81172 20.3846Z"
                fill="black"
            />
            <path
                d="M25.8256 20.2726C25.8151 20.2726 25.8045 20.2722 25.7938 20.2713L24.2643 20.1463H24.2539C22.8127 19.9893 21.5984 19.4942 20.6445 18.6749C20.5028 18.5532 20.4025 18.4543 20.3487 18.4011L20.3264 18.3789C19.4613 17.564 18.4465 16.9243 17.3382 16.4953C16.4377 16.1444 15.4602 15.9171 14.4329 15.8232C12.7871 15.6711 11.5503 15.9068 11.3971 15.938C10.1992 16.3203 9.0465 16.8322 7.95962 17.4646C6.47195 18.3322 5.88036 18.9823 5.53096 19.4515C5.36605 19.6732 5.21625 19.9058 5.08259 20.1477L4.40137 19.7716C4.552 19.499 4.72082 19.237 4.90667 18.9871C5.3001 18.4594 5.95798 17.7312 7.56641 16.7913C8.28329 16.3731 9.02862 16.0056 9.7969 15.6916C9.81747 14.5696 10.0485 13.4614 10.4779 12.4246C10.9119 11.3733 11.5443 10.4154 12.3405 9.60322C13.1294 8.7942 14.0689 8.1473 15.1061 7.69899C16.1767 7.23785 17.3301 7 18.4957 7C19.6613 7 20.8147 7.23785 21.8853 7.69899C22.9225 8.1473 23.862 8.7942 24.6509 9.60322C25.4471 10.4155 26.0795 11.3735 26.5135 12.4249C27.0166 13.6464 27.2465 14.9631 27.1871 16.2828C27.1277 17.6026 26.7805 18.8933 26.1697 20.0647C26.1367 20.1274 26.0872 20.1799 26.0266 20.2165C25.9659 20.2532 25.8964 20.2726 25.8256 20.2726ZM24.3337 19.3712L25.5961 19.4744C26.1378 18.353 26.4191 17.1236 26.419 15.8781C26.419 13.713 25.5939 11.678 24.0957 10.1481C22.5976 8.61818 20.6113 7.77883 18.4967 7.77883C16.3821 7.77883 14.3929 8.61974 12.8968 10.1477C11.5119 11.5619 10.7023 13.4079 10.5875 15.3889C10.783 15.3192 10.9799 15.2524 11.1785 15.1887C11.1899 15.185 11.2015 15.1819 11.2132 15.1794C11.2703 15.1669 12.6332 14.8733 14.5048 15.0459C15.6039 15.1482 16.6523 15.3902 17.6204 15.7683C18.822 16.2335 19.9221 16.9271 20.86 17.8106C20.8682 17.8184 20.8802 17.8302 20.8962 17.846C20.9434 17.8925 21.031 17.9794 21.1522 18.0831C21.9836 18.7974 23.054 19.2307 24.3337 19.3712Z"
                fill="black"
            />
            <path
                d="M22.9373 19.2951L22.2227 18.9866C22.8619 17.5063 23.1791 16.0115 23.1642 14.5436C23.1512 13.365 22.9282 12.1982 22.5056 11.0979C21.7819 9.20078 20.717 8.0921 20.7064 8.08098L21.2646 7.53853C21.3126 7.58813 22.4525 8.77488 23.2329 10.8206C23.6883 12.007 23.9286 13.2652 23.9423 14.5361C23.9579 16.1131 23.6194 17.7144 22.9373 19.2951ZM15.3191 15.6401C15.2392 14.4022 15.3578 12.5111 16.3755 10.5317C17.0682 9.19156 18.0604 8.02914 19.2752 7.13464L19.7367 7.76183C18.6187 8.58496 17.7054 9.65469 17.0678 10.8879C16.1315 12.709 16.0225 14.4502 16.0961 15.5903L15.3191 15.6401Z"
                fill="black"
            />
            <rect x="0.44" y="0.44" width="31.12" height="31.12" rx="3.56" stroke="black" strokeWidth="0.88" />
        </svg>
    );
}
